/* You can add global styles to this file, and also import other style files */

//== Vendor
@import '~@fortawesome/fontawesome-free-webfonts/css/fa-brands.css';
@import '~@fortawesome/fontawesome-free-webfonts/css/fa-regular.css';
@import '~@fortawesome/fontawesome-free-webfonts/css/fa-solid.css';
@import '~@fortawesome/fontawesome-free-webfonts/css/fontawesome.css';
@import '~simple-line-icons/css/simple-line-icons.css';
@import '~weather-icons/css/weather-icons.css';
@import '~weather-icons/css/weather-icons-wind.css';

@import '~loaders.css/loaders.css';

@import '~ag-grid/dist/styles/ag-grid.css';
@import '~ag-grid/dist/styles/theme-fresh.css';

@import '~jqcloud2/dist/jqcloud.css';

@import '~summernote/dist/summernote.css';

//== Bootstrap
@import "./app/shared/styles/bootstrap.scss";
//== Application
@import "./app/shared/styles/app.scss";
//== Default theme
// @import "./app/shared/styles/themes/theme.scss";

//== NgSelect
@import "~@ng-select/ng-select/themes/default.theme.css";

.modal-backdrop{
    z-index:100 !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: $primary !important;
}

// Mobile media queries
@media (max-width: 768px) {
  // Profile creation mobile configuration
  .profile-info {
    display: flex;
    flex-flow: column;

    s1-input-text, .form-group {
      max-width: 100%;
    }

    .back-btn {
      display: flex;

      button {
        width: 100%;
      }
    }
  }
}